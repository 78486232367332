import Vue from 'vue'
import VueRouter from 'vue-router'
import PaymentPage from '@/views/PaymentPage.vue'
import VaultingPage from '@/views/VaultingPage.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/vault',
        name: 'vault',
        component: VaultingPage
    },
    {
        path: '/pay',
        name: 'pay',
        component: PaymentPage
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
