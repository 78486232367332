<template>
    <div class="page-wrapper">
        <div class="page-container">
            <div class="content-container">
                <div class="vaulted-methods" v-show="vaultedmethods && vaultedmethods.length > 0">
                    <div class="headline">Gespeicherte Zahlungsmethoden</div>
                    <div class="payment-method" :class="{ 'active' : method == selectedpaymentmethod }" v-for="method in vaultedmethods" :key="method.data.reference">
                        <label :for="method.data.reference">
                            <img v-if="method.method === 0" src="@/assets/icons/paypal_logo.png" id="paypal-logo"/>
                            <img v-if="method.method === 1" src="@/assets/icons/visa.svg"        id="visa-logo"/>
                            <img v-if="method.method === 2" src="@/assets/icons/sepa_logo.svg"   id="sepa-logo"/>
                            <!-- method 3 googlePay -->
                            <!-- method 4 applePay -->
                            <img v-if="method.method === 5" src="@/assets/icons/mastercard_logo.svg" id="mastercard-logo"/>
                            <span class="vaulted-info">{{ method.data.description }}</span>
                        </label>
                        <input type="radio" :id="method.data.reference" name="payment" :value="method" v-model="selectedpaymentmethod" @change="setupPayment(method, true)">
                    </div>
                </div>

                <div class="direct-methods">
                    <div class="headline">Verfügbare Zahlungsmethoden</div>

                    <div class="payment-method" v-if="methodIsAvailable(0)" :class="{ 'active' : paypalmethod == selectedpaymentmethod }">
                        <label for="paypal">
                            <img src="@/assets/icons/paypal_logo.png" id="paypal-logo"/>
                        </label>
                        <input type="radio" id="paypal" name="payment" :value="paypalmethod" v-model="selectedpaymentmethod" @change="setupPayment(paypalmethod)"/>
                    </div>

                    <div class="payment-method" v-if="methodIsAvailable(1)" :class="{ 'active' : visamethod == selectedpaymentmethod }">
                        <label for="visa">
                            <img src="@/assets/icons/visa.svg" id="visa-logo"/>
                        </label>
                        <input type="radio" id="visa" name="payment" :value="visamethod" v-model="selectedpaymentmethod" @change="setupPayment(visamethod)"/>
                    </div>

                    <div class="payment-method" v-if="methodIsAvailable(5)" :class="{ 'active' : mastercardmethod == selectedpaymentmethod }">
                        <label for="mastercard">
                            <img src="@/assets/icons/mastercard_logo.svg" id="mastercard-logo"/>
                        </label>
                        <input type="radio" id="mastercard" name="payment" :value="mastercardmethod" v-model="selectedpaymentmethod" @change="setupPayment(mastercardmethod)"/>
                    </div>

                    <div class="payment-method" v-if="methodIsAvailable(2)" :class="{ 'active' : sepamethod == selectedpaymentmethod }">
                        <label for="sepa">
                            <img src="@/assets/icons/sepa_logo.svg" id="sepa-logo"/>
                        </label>
                        <input type="radio" id="sepa" name="payment" :value="sepamethod" v-model="selectedpaymentmethod" @change="setupPayment(sepamethod)"/>
                    </div>

                    <div class="payment-method" v-if="methodIsAvailable(3)" :class="{ 'active' : googlepaymethod == selectedpaymentmethod }">
                        <label for="google-pay">
                            <img src="@/assets/icons/google_pay_acceptance_logo.png" id="googlepay-logo"/>
                        </label>
                        <input type="radio" id="google-pay" name="payment" :value="googlepaymethod" v-model="selectedpaymentmethod" @change="setupPayment(googlepaymethod)"/>
                    </div>

                    <div class="payment-method" v-if="methodIsAvailable(4)" :class="{ 'active' : applepaymethod == selectedpaymentmethod }">
                        <label for="apple-pay">
                            <img src="@/assets/icons/apple_pay_logo.png" id="applepay-logo"/>
                        </label>
                        <input type="radio" id="apple-pay" name="payment" :value="applepaymethod" v-model="selectedpaymentmethod" @change="setupPayment(applepaymethod)"/>
                    </div>
                </div>

                <div class="finalize-payment">
                    <div class="save-sepa" v-if="!isguest && (this.selectedpaymentmethod && this.selectedpaymentmethod.psp === 2 && this.selectedpaymentmethod.method === 2 && this.selectedpaymentmethod.origin === -1)">
                        <input type="checkbox" v-model="savesepa" id="savesepa" @change="setupPayment(sepamethod, false)">
                        <label for="savesepa">Bezahlmethode speichern</label>
                    </div>

                    <form :id="this.telecashformid" method="post" :action="telecashurl" v-show="this.selectedpaymentmethod && this.selectedpaymentmethod.psp === 2">
                        <input type="hidden" name="chargetotal" :value="total" />
                        <input type="hidden" name="checkoutoption" value="combinedpage"/>
                        <input type="hidden" name="currency" value="978" />
                        <input type="hidden" name="hash_algorithm" value="HMACSHA256" />
                        <input type="hidden" name="mode" value="payonly" />
                        <input type="hidden" name="paymentMethod" :value="customer?.telecash?.method" />
                        <input type="hidden" name="responseFailURL" :value="customer?.telecash?.returnurl"/>
                        <input type="hidden" name="responseSuccessURL" :value="customer?.telecash?.returnurl"/>
                        <input type="hidden" name="storename" :value="storename" />
                        <input type="hidden" name="timezone" value="Europe/Berlin" />
                        <input type="hidden" name="txndatetime" :value="customer?.telecash?.transactiontime" />
                        <input type="hidden" name="txntype" value="sale" />
                        <input type="hidden" name="hashExtended" :value="customer?.telecash?.transactionhash"/>
                        <input type="hidden" name="assignToken" value="true" v-if="customer?.telecash?.savehosteddataid" />
                        <input type="hidden" name="hosteddataid" :value="customer?.telecash?.hosteddataid" v-if="customer?.telecash?.hosteddataid"/>
                    </form>
                </div>
            </div>
        </div>
        <div class="content-width pay-button-container">
            <button class="pay-button" v-show="showlogpaybutton" :disabled="payButtonDisabled()" @click="pay(selectedpaymentmethod)">Jetzt kaufen</button>

            <!-- telecash has a special case with paypal -->
            <button class="pay-button" v-show="!showlogpaybutton && !showpaypalbutton" :disabled="payButtonDisabled()" @click="pay(selectedpaymentmethod)">Jetzt kaufen</button>
            <div :id="paypalcontainerid" v-show="!showlogpaybutton && showpaypalbutton"></div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'

import {
    QUERY_PARAMS
} from '@/classes/QUERY_PARAMS.js'

import Payments, {
    PAYMENT_ORIGINS,
    PAYMENT_SERVICE_PROVIDERS,
    PAYMENT_METHODS,
} from '@/lib/Payment'

export default {
    name: 'payment-page',
    data() {
        return {
            amount: undefined,

            paymentinstance: Payments,
            telecashformid: 'telecash',
            paypalcontainerid: 'paypal-button',
            vaultedmethods: [],
            selectedpaymentmethod: undefined,
            sepamethod: {
                origin: PAYMENT_ORIGINS.NONE,
                psp: undefined,
                method: PAYMENT_METHODS.SEPA,
                data: {}
            },
            visamethod: {
                origin: PAYMENT_ORIGINS.NONE,
                psp: undefined,
                method: PAYMENT_METHODS.VISA,
                data: {}
            },
            mastercardmethod: {
                origin: PAYMENT_ORIGINS.NONE,
                psp: undefined,
                method: PAYMENT_METHODS.MASTERCARD,
                data: {}
            },
            paypalmethod: {
                origin: PAYMENT_ORIGINS.NONE,
                psp: undefined,
                method: PAYMENT_METHODS.PAYPAL,
                data: {}
            },
            googlepaymethod: {
                origin: PAYMENT_ORIGINS.NONE,
                psp: undefined,
                method: PAYMENT_METHODS.GOOGLEPAY,
                data: {}
            },
            applepaymethod: {
                origin: PAYMENT_ORIGINS.NONE,
                psp: undefined,
                method: PAYMENT_METHODS.APPLEPAY,
                data: {}
            },
            logpayurl: undefined,
            savesepa: false
        }
    },
    computed: {
        config() {
            return this.$config
        },
        isloading: {
            set(value) {
                this.$root.$data.loading = value
            },
            get() {
                return this.$root.$data.loading
            }
        },
        customer() {
            return this.paymentinstance.customer
        },
        telecashurl() {
            return this.config.telecashconfig.url
        },
        storename() {
            return this.config.telecashconfig.storename
        },
        total() {
            return this._currencyValueToFloat(this.amount)
        },
        showlogpaybutton() {
            return this.provider === PAYMENT_SERVICE_PROVIDERS.LOGPAY
        },
        showpaypalbutton() {
            // we only show the paypal button, if it has origin === -1 (NONE)
            // this means we want to pay directly via braintree and not the saved method via telecash
            return this.provider === PAYMENT_SERVICE_PROVIDERS.TELECASH 
                    && this.selectedpaymentmethod?.method === PAYMENT_METHODS.PAYPAL
                    && this.selectedpaymentmethod?.origin === PAYMENT_ORIGINS.NONE
        },
        provider() {
            if (this.config.paymentservices.provider === 'tc') {
                return PAYMENT_SERVICE_PROVIDERS.TELECASH
            } else if (this.config.paymentservices.provider === 'lp') {
                return PAYMENT_SERVICE_PROVIDERS.LOGPAY
            } else {
                return undefined
            }
        },
        logpaydummyitems() {
            return [
                {
                    id: 'hpp',
                    name: 'dummy',
                    price: this.amount,
                    quantity: 1
                }
            ] // item: { id: xyz, name: xyz, price: 50000, quantity: 1 }
        },
        isguest() {
            return this.paymentinstance?.oidtoken?.trim().toLowerCase().startsWith('guest') || false
        }
    },
    mounted: async function() {
        this.isloading = true

        this.amount    = this.$route.query[QUERY_PARAMS.AMOUNT]
        const oidtoken = this.$route.query[QUERY_PARAMS.TOKEN]

        const success  = this.$route.query[QUERY_PARAMS.SUCCESS]
        const error    = this.$route.query[QUERY_PARAMS.ERROR]
        const ogmethod = this.$route.query[QUERY_PARAMS.OG_METHOD]

        const paymentid     = this.$route.query[QUERY_PARAMS.PAYMENT_ID]
        const paymentmethod = this.$route.query[QUERY_PARAMS.PAYMENT_METHOD]

        // telecash return handling
        const telecashsuccess = success && parseInt(this.$route.query[QUERY_PARAMS.PSP], 10) === 8 // 8 = MerchantServer TeleCash
        const hosteddataid    = this.$route.query[QUERY_PARAMS.TELECASH_HOSTED_DATA]
        const cardno          = this.$route.query[QUERY_PARAMS.TELECASH_CARD_NO]
        
        const psp = parseInt(this.$route.query[QUERY_PARAMS.PSP], 10)
        
        if (oidtoken) {
            // if the oidtoken is not supplied, we also won't need the paymentinstance
            try {
                this.paymentinstance = await new Payments(oidtoken, this.provider, true).init()
            } catch(error) {
                this._returnPaymentResult({ error, location })
            }
        }

        if (telecashsuccess && ogmethod) {
            try {
                //get the userprofile via a new payments instance
                await this.paymentinstance.createOrUpdateTeleCashMethod(ogmethod, hosteddataid, cardno)

                // old param for compatibility
                // old param is automatically send via the telecash redirect
                // new param 'done'
                this._returnPaymentResult({ success: 'done', psp: 8, location, paymentid, oidtoken: this.paymentinstance.oidtoken }) // 8 = MerchantServer TeleCash
            } catch(err) {
                this._returnPaymentResult({ error: err, location })
            }

            return
        // else we only redirect with a success
        } else if (success && success !== 'done') {
            // old param for compatibility
            // middleware send 'success=1' no need for double redirect
            // new param 'done'
            this._returnPaymentResult({ success: 'done', psp, paymentid, paymentmethod, location })
        } else if (error) {
            // nothing to do here, the url that is returned from the mw
            // already has the correct format
            return
        } else {
        if (!oidtoken) {
                throw 'user missing'
            }

            if (!this.amount) {
                throw 'no payment amount'
            }

            this.init()
            .then(() => {
                this.isloading = false
            })
            .catch(err => {
                console.log('Error while mounting')
                this._returnPaymentResult({ error: err, location })
            })
        }
    },
    methods: {
        init: async function() {
            this.applepaymethod.psp   = this.provider
            this.googlepaymethod.psp  = this.provider
            this.mastercardmethod.psp = this.provider
            this.visamethod.psp       = this.provider
            this.sepamethod.psp       = this.provider

            if (this.provider === PAYMENT_SERVICE_PROVIDERS.TELECASH) {
                this.paypalmethod.psp = PAYMENT_SERVICE_PROVIDERS.PAYPAL
            } else {
                this.paypalmethod.psp = this.provider
            }

            // Telecash
            if (this.provider === PAYMENT_SERVICE_PROVIDERS.TELECASH) {
                this.customer.telecash.methods?.forEach(ref => {
                    this.addVaultedMethod(ref)
                })

            // Logpay
            } else if (this.provider === PAYMENT_SERVICE_PROVIDERS.LOGPAY) {
                this.customer.logpay.methods?.forEach(ref => {
                    this.addVaultedMethod(ref)
                })
            }
        },
        methodIsAvailable(method) {
            switch(method) {
                case PAYMENT_METHODS.PAYPAL:
                    return this.config.paymentservices.paypal
                case PAYMENT_METHODS.VISA:
                    return this.config.paymentservices.cc
                case PAYMENT_METHODS.MASTERCARD:
                    return this.config.paymentservices.cc
                case PAYMENT_METHODS.SEPA:
                    return this.config.paymentservices.sepa
                case PAYMENT_METHODS.GOOGLEPAY:
                    return this.config.paymentservices.googlepay
                case PAYMENT_METHODS.APPLEPAY:
                    return this.config.paymentservices.applepay
                default:
                    return false;
            }
        },
        payButtonDisabled() {
            return this.selectedpaymentmethod === undefined
        },
        addVaultedMethod: function(vaultedmethod) {
            // only show the vaulted methods to the configured provider
            if (vaultedmethod.psp === this.provider && vaultedmethod.method !== undefined && this.methodIsAvailable(vaultedmethod.method)) {
                this.vaultedmethods.push(vaultedmethod)
            }
        },
        setupPayment: async function(method, vaulted) {
            this.isloading = true

            if (this.provider === PAYMENT_SERVICE_PROVIDERS.TELECASH) {
                if (!vaulted && method.method === PAYMENT_METHODS.PAYPAL) {
                    this.initPaypal()
                } else {
                    await this.setupTeleCash(method, vaulted)
                }
            } else if (this.provider === PAYMENT_SERVICE_PROVIDERS.LOGPAY) {
                await this.setupLogPay(method, vaulted)
            }

            // avoid screen flickering
            setTimeout(() => {
                this.isloading = false
            }, 300)
        },
        initPaypal() {
            const scriptid = 'paypal-checkout'

            // paypal setup
            if (!document.getElementById(scriptid)) {
                const script = document.createElement('script')
                script.src = 'https://www.paypalobjects.com/api/checkout.js'
                script.id = scriptid
                script.addEventListener('load', this.setupPaypal)
                document.body.append(script)
            } else {
                this.setupPaypal()
            }
        },
        setupPaypal() {
            // only render a new button if there isn't one already
            const paypalcontainer = document.getElementById(this.paypalcontainerid)
            if (paypalcontainer.firstChild) {
                return
            }

            const price = this._currencyValueToFloat(this.amount)
            const inner = this

            window.paypal.Button.render({
                // Configure environment
                env: inner.config.paypalconfig.clientidprod ? 'production' : 'sandbox',
                client: {
                    sandbox: inner.config.paypalconfig.clientid,
                    production: inner.config.paypalconfig.clientidprod
                },
                // Customize button (optional)
                locale: 'de_DE',
                style: {
                    size:    'large',
                    color:   'blue',
                    shape:   'rect',
                    label:   'paypal',
                    tagline: 'false'
                },

                // Enable Pay Now checkout flow (optional)
                commit: true,

                // Set up a payment
                payment: function(data, actions) {
                    return actions.payment.create({
                        transactions: [{
                            amount: {
                                total: price,
                                currency: inner.config.paypalconfig.currency
                            }
                        }]
                    })
                },
                // Execute the payment
                onAuthorize: function(data, actions) {
                    return actions.payment.execute()
                    .then(result => {
                        // old params for compatibility
                        inner._returnPaymentResult({ success: 1, paymentid: result.id, psp: 5, paymentmethod: 5, location }) // psp=5 === paypal, pm=5 === paypal
                        // new param 'done'
                        inner._returnPaymentResult({ success: 'done', paymentid: result.id, psp: 5, paymentmethod: 5, location }) // psp=5 === paypal, pm=5 === paypal
                    })
                },
                onCancel: function() {
                    console.log('paypal aborted')
                },
                onError: function(err) {
                    inner._returnPaymentResult({ error: err, location})
                }
            }, '#' + this.paypalcontainerid)
        },
        setupTeleCash: async function(method, vaulted) {
            // paypal is handled via braintree
            if (method.origin === PAYMENT_ORIGINS.BRAINTREE) {
                return new Promise(res => res(undefined))
            }

            let returnurl = Vue.middleware() + '/payment/telecash/hpp/return'

            if (this.savesepa) {
                returnurl += `?${QUERY_PARAMS.TOKEN}=${this.paymentinstance.oidtoken}`
                returnurl += `&${QUERY_PARAMS.OG_METHOD}=${method.method}`
            }

            try {
                await this.paymentinstance.prepareTeleCash(method.method, returnurl, false, this.total, vaulted, this.savesepa)

                // prevent screen flickering
                setTimeout(() => {
                    if (method.method === PAYMENT_METHODS.SEPA) {
                        // scroll to "save sepa" checkbox
                        const el = document.getElementById('router-view')
                        el.scrollTop = el.scrollHeight
                    }

                    this.isloading = false
                }, 500)
            } catch(err) {
                this._returnPaymentResult({ error: err.message, location })
            }
        },
        setupLogPay: async function(method, vaulted) {
            if (vaulted) {
                // if we use a vaulted method, we don't need the setup step
                this.logpayurl = undefined
                return new Promise(res => res(undefined))
            }

            try {
                this.logpayurl = await this.paymentinstance.getLogPayDirectPaymentUrl(method.method, this.amount)
            } catch(err) {
                this._returnPaymentResult({ error: err.message, location })
            }
        },
        pay: async function(method) {
            this.isloading = true

            try {
                if (this.provider === PAYMENT_SERVICE_PROVIDERS.TELECASH) {
                    if (method.origin === PAYMENT_ORIGINS.BRAINTREE) {
                        const transaction = await this.paymentinstance.paypalVaultedPurchase(method, this.amount)
                        // psp=5 === paypal, pm=5 === paypal
                        // old param for compatibility
                        this._returnPaymentResult({ success: 1, paymentid: transaction.paypal.paymentId, psp: 5, paymentmethod: 5, location })
                        // new param 'done'
                        this._returnPaymentResult({ success: 'done', paymentid: transaction.paypal.paymentId, psp: 5, paymentmethod: 5, location })
                    } else {
                        document.getElementById(this.telecashformid).submit()
                    }
                } else if (this.provider === PAYMENT_SERVICE_PROVIDERS.LOGPAY) {
                    // logpay has two different flows for direct and vaulted purchases
                    let vaulted = method.data?.reference

                    if (vaulted) {
                        const response = await this.paymentinstance.logPayVaultedPurchase(method, this.amount)
                        // { dddsecurehtml, oid, psp, paymentmethod }
                        if (response.dddsecurehtml) {
                            // TODO: 3D-Secure flow?
                            // it seems that the 3D secure flow is handled while
                            // vaulting the card, not when making the purchase
                        } else {
                            // else we are done
                            // psp=3 === logpay
                            // old param for compatibility
                            this._returnPaymentResult({ success: 1, paymentid: response.oid, psp: 3, paymentmethod: response.paymentmethod, location })
                            // new param 'done'
                            this._returnPaymentResult({ success: 'done', paymentid: response.oid, psp: 3, paymentmethod: response.paymentmethod, location })
                        }
                    } else {
                        window.location.href = this.logpayurl
                    }
                }
            } catch(err) {
                this._returnPaymentResult({ error: err, location})
            }

            this.isloading = false
        }
    },
}
</script>

<style scoped>
.page-wrapper {
    background: #31525F;
}

.pay-button-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

.content-container {
    padding-bottom: 90px;
}

.headline {
    border-bottom: 1px solid #6d858f;
    text-align: left;
    color: #fff;
    margin-top: 20px;
    font-size: 1.3em;
    padding-bottom: 10px;
}

.payment-method {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    text-align: left;
    background: #43616d;
    margin-top: 10px;
}

.payment-method.active {
    border: 2px solid #c94191;
}

.payment-method > label {
    color: #fff;
    padding: 5px 20px;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.payment-method > input {
    visibility: hidden;
}

.payment-method > label > img {
    box-sizing: border-box;
    height: 50px;
    object-fit: contain;
}

.payment-method > label > .vaulted-info {
    font-size: 1.2em;
}

#paypal-logo {
    padding: 10px;
}

#visa-logo {
    padding: 10px;
}

#mastercard-logo {
    padding: 5px;
}

#sepa-logo {
    background: #fff;
    border-radius: 8px;
    padding: 5px;
    height: 40px;
}

#googlepay-logo {
    padding: 5px;
}

#applepay-logo {
    padding: 5px;
}

.finalize-payment {
    width: 100%;
    margin-top: auto;
}

.pay-button {
    height: 50px;
    width: 100%;
    /* margin: 20px 0 10px 0; */
    background: #c94191;
    color: #fff;
    text-transform: unset;
    font-size: 1.3em;
    border-radius: 8px;
}

.pay-button:disabled {
    background: #8d487d;
    color: rgba(255, 255, 255, 0.3);
}

.save-sepa {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.save-sepa:hover {
    cursor: pointer;
}

.save-sepa > input {
    transform: scale(1.5);
}

.save-sepa > label {
    color: #fff;
    font-size: 1.3em;
    margin-left: 10px;
}

*:deep(.zoid-outlet) {
    width: 100% !important;
}
</style>