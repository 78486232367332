import Vue from 'vue'
import axios from 'axios'
import braintree from 'braintree-web'

export const BRAINTREE_PSP_NAME = 'Braintree'

function getBraintreeToken(customerid) {
    let url = Vue.middleware()
    url += '/payment/braintree/customers/' + customerid
    url += '/token'

    return axios.get(url)
    .then(response => {
        return new Promise(res => res(response.data.token))
    })
}

// returns braintree userid or undefined
export function getBraintreeCustomer(customerid) {
    if (!customerid) {
        return new Promise(res => res(undefined))
    }

    let url = Vue.middleware()
    url += '/payment/braintree/customers/' + customerid

    return axios.get(url)
    .then(response => {
        let result

        if (response.status === 204) {
            result = undefined
        } else {
            result = response.data
        }

        return new Promise((res) => res(result))
    })
}

function getBraintreeClient(customerid) {
    return getBraintreeCustomer(customerid)
    .then(customer => {
        return getBraintreeToken(customer.id)
    })
    .then(token => {
        return braintree.client.create({
            authorization: token
        })
    })
}

export function getDeviceData(customerid) {
    return getBraintreeClient(customerid)
    .then(clientinstance => {
        return braintree.dataCollector.create({
            client: clientinstance
        })
    })
    .then(datacollectorinstance => {
        return new Promise(res => res(datacollectorinstance.deviceData))
    })
}

export async function createBraintreeCustomer(firstname, lastname, email, profile, oidtoken, psp) {
    let url = Vue.middleware()
    url += '/payment/braintree/customers'

    let customer = {
        braintreeid: undefined,
        firstname,
        lastname,
        email,
        profile,
        braintree: {
            methods: undefined,
            email: undefined
        }
    }

    return axios.put(url, customer)
    .then(response => {
        const customerid = response.data.customerid
        return new Promise((res) => res(customerid))
    })
    .then(customerid => {
        customer.braintree.id = customerid

        let savebraintreepspurl = Vue.middleware()
        savebraintreepspurl += '/account/userprofile/' + oidtoken

        if (!customer.profile.lists.paymentReferences) {
            customer.profile.lists.paymentReferences = []
        }

        // TODO: psp === BRAINTREE_PSP_NAME when available
        customer.profile.lists.paymentReferences.push({
            paymentServiceProvider: psp,
            paymentMethod: 'PayPal',
            paymentMethodReference: customerid,
            paymentMethodDescription: BRAINTREE_PSP_NAME
        })

        return axios.put(savebraintreepspurl, customer.profile)
        .then(() => {
            return customer
        })
    })
}

export function getPayPalVaultInstance(customerid) {
    return getBraintreeClient(customerid)
    .then(client => {
        return braintree.paypalCheckout.create({
            client: client
        })
    })
    .then(paypalinstance => {
        return paypalinstance.loadPayPalSDK({
            vault: true
        })
    })
}

export function removePaymentMethod(customerid) {
    return getBraintreeCustomer(customerid)
    .then(customer => {
        const method = customer.paymentMethods.find(method => method.default)

        let removeurl = Vue.middleware()
        removeurl += '/payment/braintree/customers/' + method.customerId
        removeurl += '/methods/' + method.token

        return axios.delete(removeurl)
    })
}

export function vaultPaypalAgreement(customerid, nonce) {
    let url = Vue.middleware()
    url += '/payment/braintree/customers/' + customerid
    url += '/methods'

    const body = {
        paymentnonce: nonce
    }

    return axios.put(url, body)
}

export function vaultedPurchase(customerid, methodtoken, amount) {
    return getDeviceData(customerid)
    .then(devicedata => {
        let salesurl = Vue.middleware()
        salesurl += '/payment/braintree/customers/' + customerid
        salesurl += '/sale'

        const salesbody = {
            amountincent: amount,
            methodtoken: methodtoken,
            devicedata: devicedata
        }

        return axios.post(salesurl, salesbody)
    })
}