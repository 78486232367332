import Vue from 'vue'
import axios from 'axios'

export function getLogPayCustomer(customerid) {
    if (!customerid) {
        return new Promise(res => res(undefined))
    }

    let url = Vue.middleware()
    url += '/payment/logpay/customers/' + customerid

    return axios.get(url)
    .then(response => {
        let result

        if (response.status === 204) {
            result = undefined
        } else {
            result = response.data
        }

        return new Promise((res) => res(result))
    })
}

export function createLogPayUser(email, firstname, lastname, birthdate, street, housenr, zip, city, internalid, country) {
    const customer = {
        email,
        firstname,
        lastname,
        birthdate,
        street,
        housenr,
        zip,
        city,
        country,
        internalid
    }

    let url = Vue.middleware()
    url += '/payment/logpay/customers'

    return axios.put(url, customer)
    .then(response => {
        if (response.status === 400) {
            throw new Error(response.statusText)
        } else {
            return new Promise(res => res(response.data))
        }
    })
}

export function createOrUpdateLogPayReference(oidtoken, profile, reference, method = undefined, description = undefined) {
    let saveprofileurl = Vue.middleware()
    saveprofileurl += '/account/userprofile/' + oidtoken

    if (!profile.lists.paymentReferences) {
        profile.lists.paymentReferences = []
    }

    let reftoupdate = profile.lists.paymentReferences.find(ref => {
        const isprovider    = ref.paymentServiceProvider   === 'LogPay'
        const ismethod      = ref.paymentMethod            === method
        const isdescription = ref.paymentMethodDescription === description

        return isprovider && ismethod && isdescription
    })

    if (reftoupdate) {
        reftoupdate.paymentMethodReference = reference
    } else {
        profile.lists.paymentReferences.push({
            paymentServiceProvider:   'LogPay',
            paymentMethod:            null,
            paymentMethodReference:   reference,
            paymentMethodDescription: 'hpp'
        })
    }

    return axios.put(saveprofileurl, profile)
    .then(response => {
        return response.data
    })
}

export function vaultLogPayMethod(customerid, method, internalid) {
    let url = Vue.middleware()
    url += '/payment/logpay/customers/' + customerid
    url += '/methods'

    return axios.put(url, { internalid, method })
}
export function favourLogPayMethod(customerid, methodid) {
    let url = Vue.middleware()
    url += '/payment/logpay/customers/' + customerid
    url += '/methods/' + methodid
    url += '/favour'

    return axios.put(url, {})
}

export function disableLogPayMethod(customerid, methodid) {
    let url = Vue.middleware()
    url += '/payment/logpay/customers/' + customerid
    url += '/methods/' + methodid

    return axios.delete(url)
}

export function confirmLogPayVault(customerid) {
    let url = Vue.middleware()
    url += '/payment/logpay/customers/' + customerid
    url += '/confirm-vault'

    return axios.put(url, {})
}

export function vaultLogPayPayPal(customerid, oidtoken, paypalemail, paypalagreementid, paypaldevicedata) {
    let url = Vue.middleware()
    url += '/payment/logpay/customers/' + customerid
    url += '/vault-paypal'

    const body = {
        internalid:    oidtoken,
        email:         paypalemail,
        agreementid:   paypalagreementid,
        secureelement: paypaldevicedata
    }

    return axios.put(url, body)
}

export function vaultedLogPayPurchase(customerid, method, total, items, methodreference, ogmethod) {
    let url = Vue.middleware()
    url += '/payment/logpay/customers/' + customerid
    url += '/methods/' + methodreference
    url += '/pay'

    const body = {
        total,
        items,
        method,
        ogmethod
    }

    return axios.put(url, body)
    .then(response => {
        return new Promise(res => res(response.data))
    })
}

export function getDirectPaymentUrl(method, amount, items) {
    let url = Vue.middleware() + '/payment/logpay/direct-payment-url'

    url += `?method=${method}`
    url += `&total=${amount}`
    url += `&items=${JSON.stringify(items)}`
    url += `&hpp=true`

    return axios.get(url)
    .then(response => {
        return new Promise(res => res(response.data.redirect))
    })
}