import Vue from 'vue'
import axios from 'axios'

export function getNpsUserProfile(oidtoken) {
    if (!oidtoken) {
        return new Promise(res => res(undefined))
    }

    let url = Vue.middleware()
    url += '/account/userprofile/' + oidtoken
    url += '?includeProperties=personalInformation&includeLists=paymentReferences'

    return axios.patch(url)
}

export function getNpsAdressData(oidtoken) {
    const baseurl = Vue.middleware()

    let idurl = baseurl
    idurl += '/userprofile/keycloak/identify/' + oidtoken

    return axios.get(idurl)
    .then(response => {

        let addressurl = baseurl
        addressurl += '/userprofile/user/' + response.data.userid
        addressurl += '/address'

        return axios.get(addressurl)
    })
}