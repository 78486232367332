export const PAYMENT_ORIGINS = {
    NONE:     -1,
    NPS:       0,
    BRAINTREE: 1,
    TELECASH:  2,
    LOGPAY:    3,
    PAYPAL:    4
}

export const PAYMENT_SERVICE_PROVIDERS = {
    PAYPAL:    0,
    BRAINTREE: 1,
    TELECASH:  2,
    LOGPAY:    3
}

export const PAYMENT_METHODS = {
    PAYPAL:     0,
    VISA:       1,
    SEPA:       2,
    GOOGLEPAY:  3,
    APPLEPAY:   4,
    MASTERCARD: 5,
}

export const BACKEND_PAYMENT_METHOD = {
    bd_fake:                0, // "Pseudo-Bezahldienst"
    bd_Digicash:            1, // "Digicash"
    bd_FLASHiZ:             2, // "FLASHiZ"
    bd_Code:                3, // "Code"
    bd_Saferpay:            4, // "Saferpay"
    bd_PayPal:              5, // "PayPal"
    bd_Payeezy:             6, // "Payeezy"
    bd_DirectBilling:       7, // "DirectBilling"
    bd_Zeitmeilen:          8, // "Zeitmeilen"
    bd_Adyen:               9, // "Adyen"
    bd_Sammelabrechnung:   10, // "Sammelabrechnung"
    bd_PayOne:             11, // "PayOne"
    bd_Kreditkarte:        12, // "Kreditkarte"
    bd_Visa:               13, // "Visa"
    bd_Mastercard:         14, // "Mastercard"
    bd_Amex:               15, // "Amex"
    bd_Jcb:                16, // "JCB"
    bd_Diners:             17, // "Diners"
    bd_Sofortueberweisung: 18, // "Sofortüberweisung"
    bd_Paydirekt:          19, // "Paydirekt"
    bd_LogPay:             20, // "LogPay"
    bd_Undefined:          21, // "Undefined"
    bd_Bar:                22, // "Bar"
    bd_DirectDebit:        23, // "DirectDebit"
    bd_Debit_Payment:      24, // "Debit Payment"

    bd_GooglePay:          27, // googlePay
    bd_ApplePay:           28, // applePay
}

// og merchant server enums
// psp_undefined(0, "Undefined"),
// psp_pseudo(1, "Pseudo-Bezahldienst"),
// psp_adyen(2, "Adyen"),
// psp_logpay(3, "LogPay"),
// psp_payone(4, "PayOne"),
// psp_paypal(5, "PayPal"),
// psp_zeitmeilen(6, "Zeitmeilen"),
// psp_sammelabrechnung(7, "Sammelabrechnung"),
// psp_telecash(8, "TeleCash");

// bd_fake(0, "Pseudo-Bezahldienst"),
// bd_Digicash(1, "Digicash"),
// bd_FLASHiZ(2, "FLASHiZ"),
// bd_Code(3, "Code"),
// bd_Saferpay(4, "Saferpay"),
// bd_PayPal(5, "PayPal"),
// bd_Payeezy(6, "Payeezy"),
// bd_DirectBilling(7, "DirectBilling"),
// bd_Zeitmeilen(8, "Zeitmeilen"),
// bd_Adyen(9, "Adyen"),
// bd_Sammelabrechnung(10, "Sammelabrechnung"),
// bd_PayOne(11, "PayOne"),
// bd_Kreditkarte(12, "Kreditkarte"),
// bd_Visa(13, "Visa"),
// bd_Mastercard(14, "Mastercard"),
// bd_Amex(15, "Amex"),
// bd_Jcb(16, "JCB"),
// bd_Diners(17, "Diners"),
// bd_Sofortueberweisung(18, "Sofortüberweisung"),
// bd_Paydirekt(19, "Paydirekt"),
// bd_LogPay(20, "LogPay"),
// bd_Undefined(21, "Undefined"),
// bd_Bar(22, "Bar"),
// bd_DirectDebit(23, "DirectDebit"),
// bd_Debit_Payment(24, "Debit Payment");
// 27 googlepay
// 28 appleay