import Vue from 'vue'
import axios from 'axios'

export function removeTeleCashMethod(oidtoken, reference) {
    const pspname = 'TeleCash'
    
    let removemethodurl = Vue.middleware()
    removemethodurl += '/account/userprofile/' + oidtoken
    removemethodurl += '/payment-references/' + pspname
    removemethodurl += '?reference=' + reference

    return axios.delete(removemethodurl)
}

export function createOrUpdateTeleCashMethod(oidtoken, profile, method, reference, description) {
    let saveprofileurl = Vue.middleware()
    saveprofileurl += '/account/userprofile/' + oidtoken

    if (!profile.lists.paymentReferences) {
        profile.lists.paymentReferences = []
    }

    let reftoupdate = profile.lists.paymentReferences.find(ref => ref.paymentServiceProvider === 'TeleCash' && ref.paymentMethod === method)

    if (reftoupdate) {
        reftoupdate.paymentMethodReference   = reference
        if (description) reftoupdate.paymentMethodDescription = description
    } else {
        profile.lists.paymentReferences.push({
            paymentServiceProvider: 'TeleCash',
            paymentMethod: method,
            paymentMethodReference: reference,
            paymentMethodDescription: description
        })
    }

    return axios.put(saveprofileurl, profile)
    .then(response => {
        return response.data
    })
}

export function getTransactionHash(transactiontime, methodtovault, returnurl, vault = true, amount = "0.0", hosteddataid = undefined , savehosteddataid = true) {
    let url = Vue.middleware() + '/payment/telecash/verify'
    url += `?total=${amount}`
    url += `&txntime=${transactiontime}`
    url += `&method=${methodtovault}`
    // only append return url if set, else the ones configured in the middleare will come into effect
    url += returnurl ? `&hppreturn=${encodeURIComponent(returnurl)}` : ''
    url += `&txntype=${vault ? 'preAuth' : 'sale'}`

    if (savehosteddataid) {
        url += '&assignToken=true'
    }

    if (hosteddataid) {
        url += `&hosteddataid=${hosteddataid}`
    }

    return axios.get(url)
    .then(response => {
        if (response.status === 200) {
            return new Promise(res => res(response.data.hash))
        } else {
            throw new Error(response.status + ': unable to get transaction hash')
        }
    })
}