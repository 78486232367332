export const QUERY_PARAMS = {
    TOKEN:          'token',
    AMOUNT:         'amount',
    SUCCESS:        'success',
    ERROR:          'error',
    PSP:            'psp',
    OG_METHOD:      'ogmethod',
    PAYMENT_ID:     'oid',
    PAYMENT_METHOD: 'pm',
    // ----- TELECASH
    TELECASH_HOSTED_DATA: 'hosteddataid',
    TELECASH_SAVE_USER:   'tcsave',
    TELECASH_CARD_NO:     'cardno',
    // ----- LOGPAY
    LOGPAY_CONFIRM_USER: 'confirm',
    LOGPAY_USER_ID:      'lpuid'
}