import Vue from 'vue'
import App from './App.vue'
import util from '@/plugins/util'
import Icon from 'vue-awesome/components/Icon'
import mixin from './plugins/mixin'
import router from '@/plugins/router'
import Config from './plugins/config.js'
import clickoutside from '@/plugins/clickoutside.js'


const config = new Config(function() {
    Vue.prototype.$config = config.current()

    Vue.config.productionTip = false

    Vue.component('v-icon', Icon)

    new Vue({
        data: {
            loading: false
        },
        router,
        util,
        mixin,
        clickoutside,
        render: h => h(App)
    }).$mount('#app')
})